<template>
  <div id="track-back">
    <div v-if="store === 0">
      <div
        class="website"
        v-for="(a, index) in li"
        :key="index"
        v-show="index === isShow"
      >
        <img :src="a.trademark" alt="" />
        <div>
          <p>{{ a.cont }}官网查询链接：</p>
          <a :href="a.url" target="_blank">{{ a.url }}</a>
        </div>
      </div>
      <div class="website" v-show="7 === isShow">
        <img src="" alt="" />
        <div>
          <p>官网查询链接：</p>
          <a href="" target="_blank"></a>
        </div>
      </div>
      <div class="content_box">
        <div ref="nav" class="nav">
          <!-- <svg-icon
          icon-class="TrackBack-arrow-left"
          class="arrow"
          @click="leftclick"
        /> -->
          <div>
            <ul ref="ulWidth">
              <li
                v-for="(list, index) in li"
                :key="index"
                :class="[index === isShow ? 'onLi' : '']"
              >
                <svg-icon :icon-class="list.icon" />
                <p @click="OnLi(index)">{{ list.cont }}</p>
                <img src="@/assets/images/TrackBack-arrow.png" alt="" />
              </li>
              <li :class="[7 === isShow ? 'onLi' : '']">
                <svg-icon icon-class="xfz_11icon" />
                <p @click="OnLi(7)">消费者</p>
              </li>
            </ul>
          </div>
          <!-- <svg-icon
          icon-class="TrackBack-arrow-right"
          class="arrow"
          @click="rightclick"
        /> -->
        </div>
        <div
          class="content"
          v-for="(list, index) in li"
          :key="index"
          v-show="index === isShow"
        >
          <div
            class="imgBox"
            :class="[
              isShow === 0 ? 'imgBox-y' : '',
              isShow === 6 ||
              isShow === 3 ||
              isShow === 4 ||
              isShow === 1 ||
              isShow === 5
                ? 'imgBox-auto'
                : '',
            ]"
            ref="IMG"
          >
            <img
              :src="img"
              alt=""
              class="documents"
              v-for="(img, i) in list.img"
              :key="i"
              @click="goA(i)"
            />
          </div>
          <!-- <p
          @click="copy"
          :data-clipboard-text="list.odd"
          id="express_info_right"
        >
          复制
        </p> -->
          <div
            class="arrow-left arrow"
            @click="up"
            :class="[
              isShow === 4 ? 'arrow-qs' : '',
              isShow === 6 ? 'arrow-yh' : '',
            ]"
          >
            <img src="@/assets/images/Goods-left.png" />
          </div>
          <div
            class="arrow-right arrow"
            @click="down"
            :class="[
              isShow === 4 ? 'arrow-qs' : '',
              isShow === 6 ? 'arrow-yh' : '',
            ]"
          >
            <img src="@/assets/images/Goods-right.png" />
          </div>
          <!-- <div class="text">
          <div class="title">
            <img src="@/assets/images/tack-back-icon.png" alt="" />总署概况
          </div>
          <div class="cont">
            <h3>中华人民共和国海关总署</h3>
            <p>
              中华人民共和国海关总署是国务院直属机构，为正部级。负责全国海关工作、组织推动岸“大通关”
              建设、海关监管工作、进出口关税及其他税费征收管理、出入境卫生检疫和出入境动植及其产品检验
              检疫、进出口商品法定检验、海关风险管理、国家进出口货物贸易等海关统计、全国打走私综合治理
              工作、制定并组织实施海关科技发展规划以及实验室建设和技术保障规划、海关领域国合作与交流、
              垂直管理全国海关、完成党中央国务院交办的其他任务。中央纪委国家监委在海关总署驻纪检监察组。
            </p>
            <p>
              网址：<a href="http://www.customs.gov.cn/"
                >http://www.customs.gov.cn/</a
              >
            </p>
          </div>
        </div> -->
        </div>
        <div class="xfz" v-show="7 === isShow">
          <div class="Box" ref="IMG">
            <div class="cont1">
              <img :src="Store.enterpriseHomePageItems[0].storeLogo" alt="" />
              <p>{{ CommodityDetails.manufacturer }}</p>
              <h3>
                －{{
                  Store.enterpriseHomePageItems[0].storeCommodityItems[0]
                    .retailPrice | Price
                }}
              </h3>
            </div>
            <div class="cont2">
              <ul style="margin-bottom: 17px">
                <li>商品编号</li>
                <li>单价</li>
                <li>数量</li>
                <li>小计</li>
              </ul>
              <ul>
                <li>品名</li>
                <li>规格</li>
                <li>单位</li>
                <li>批号</li>
                <li>厂家</li>
                <li>产地</li>
              </ul>
            </div>
            <div class="cont3">
              <ul>
                <li>{{ CommodityDetails.barcode }}</li>
                <li>
                  {{
                    Store.enterpriseHomePageItems[0].storeCommodityItems[0]
                      .retailPrice | Price
                  }}
                </li>
                <li>1.0</li>
                <li>
                  {{
                    Store.enterpriseHomePageItems[0].storeCommodityItems[0]
                      .retailPrice | Price
                  }}
                </li>
              </ul>
              <ul>
                <li class="name" :title="CommodityDetails.commodityName">
                  {{ CommodityDetails.commodityName }}
                </li>
                <li>{{ CommodityDetails.specsParameter }}</li>
                <li>{{ CommodityDetails.prickle }}</li>
                <li>批号：20200803</li>
              </ul>
              <ul style="margin-bottom: 0">
                <li class="storeName" :title="CommodityDetails.manufacturer">
                  {{ CommodityDetails.manufacturer }}
                </li>
                <li>{{ CommodityDetails.province }}</li>
              </ul>
            </div>
            <div class="cont4">
              <div>
                <p>当前状态</p>
                <span>支付成功</span>
              </div>
              <div>
                <p>商家</p>
                <a :href="manufacturer" target="_blank">{{
                  CommodityDetails.manufacturer
                }}</a>
              </div>
              <div>
                <p>支付时间</p>
                <span>{{ CommodityDetails.createTime | nowTime }}</span>
              </div>
              <div>
                <p>支付方式</p>
                <span>广州银行储蓄卡（1618）</span>
              </div>
              <div>
                <p>交易单号</p>
                <span>4200000837202101126383104318</span>
              </div>
              <div style="margin-bottom: 100px">
                <p>商户单号</p>
                <span>可在支持的商户扫码退款</span>
              </div>
              <img src="@/assets/images/xfz-.png" alt="" />
              <p style="font-size: 24px; margin-top: 5px; color: #333333">
                48956212354798622021320141
              </p>
            </div>
            <div class="cont5">
              <div>
                <p>电子保单</p>
                <span>1587588455464554</span>
              </div>
              <div>
                <p>保单机构</p>
                <a
                  href="http://www.guorenpcic.com/web/bdyz/index.jhtml"
                  target="_blank"
                  >国任保险</a
                >
              </div>
              <div>
                <p>有效时间</p>
                <span
                  >{{ CommodityDetails.createTime | nowDay }}至{{
                    CommodityDetails.createTime | oneYear
                  }}</span
                >
              </div>
              <div>
                <p>物流轨迹</p>
                <a @click="isShow = 3">商品全生命周期在线检索</a>
              </div>
            </div>
          </div>
          <div class="arrow-left arrow" @click="up">
            <img src="@/assets/images/Goods-left.png" />
          </div>
          <div class="arrow-right arrow" @click="down">
            <img src="@/assets/images/Goods-right.png" />
          </div>
        </div>
      </div>
    </div>
    <div v-if="store === 1">
      <div
        class="website"
        v-for="(a, index) in xf"
        :key="index"
        v-show="index === isShow"
      >
        <img :src="a.trademark" alt="" />
        <div>
          <p>{{ a.cont }}官网查询链接：</p>
          <a :href="a.url" target="_blank">{{ a.url }}</a>
        </div>
      </div>
      <div class="website" v-show="8 === isShow">
        <img src="" alt="" />
        <div>
          <p>官网查询链接：</p>
          <a href="" target="_blank"></a>
        </div>
      </div>
      <div class="content_box">
        <div ref="nav" class="nav">
          <!-- <svg-icon
          icon-class="TrackBack-arrow-left"
          class="arrow"
          @click="leftclick"
        /> -->
          <div>
            <ul ref="ulWidth">
              <li
                v-for="(list, index) in xf"
                :key="index"
                :class="[index === isShow ? 'onLi' : '']"
              >
                <svg-icon :icon-class="list.icon" />
                <p @click="OnLi(index)">{{ list.cont }}</p>
                <img src="@/assets/images/TrackBack-arrow.png" alt="" />
              </li>
              <li :class="[8 === isShow ? 'onLi' : '']">
                <svg-icon icon-class="xfz_11icon" />
                <p @click="OnLi(8)">消费者</p>
              </li>
            </ul>
          </div>
          <!-- <svg-icon
          icon-class="TrackBack-arrow-right"
          class="arrow"
          @click="rightclick"
        /> -->
        </div>
        <div
          class="content"
          v-for="(list, index) in xf"
          :key="index"
          v-show="index === isShow"
        >
          <div
            class="imgBox"
            :class="[
              isShow === 0 ||
              isShow === 3 ||
              isShow === 4 ||
              isShow === 1 ||
              isShow === 5 ||
              isShow === 6
                ? 'imgBox-auto'
                : '',
            ]"
            ref="IMG"
          >
            <img
              :src="img"
              alt=""
              class="documents"
              v-for="(img, i) in list.img"
              :key="i"
              @click="goA(i)"
            />
          </div>
          <!-- <p
          @click="copy"
          :data-clipboard-text="list.odd"
          id="express_info_right"
        >
          复制
        </p> -->
          <div
            class="arrow-left arrow"
            @click="up"
            :class="[
              isShow === 0 || isShow === 4 ? 'arrow-qs' : '',
              isShow === 6 ? 'arrow-yh' : '',
            ]"
          >
            <img src="@/assets/images/Goods-left.png" />
          </div>
          <div
            class="arrow-right arrow"
            @click="down"
            :class="[
              isShow === 0 || isShow === 4 ? 'arrow-qs' : '',
              isShow === 6 ? 'arrow-yh' : '',
            ]"
          >
            <img src="@/assets/images/Goods-right.png" />
          </div>
          <!-- <div class="text">
          <div class="title">
            <img src="@/assets/images/tack-back-icon.png" alt="" />总署概况
          </div>
          <div class="cont">
            <h3>中华人民共和国海关总署</h3>
            <p>
              中华人民共和国海关总署是国务院直属机构，为正部级。负责全国海关工作、组织推动岸“大通关”
              建设、海关监管工作、进出口关税及其他税费征收管理、出入境卫生检疫和出入境动植及其产品检验
              检疫、进出口商品法定检验、海关风险管理、国家进出口货物贸易等海关统计、全国打走私综合治理
              工作、制定并组织实施海关科技发展规划以及实验室建设和技术保障规划、海关领域国合作与交流、
              垂直管理全国海关、完成党中央国务院交办的其他任务。中央纪委国家监委在海关总署驻纪检监察组。
            </p>
            <p>
              网址：<a href="http://www.customs.gov.cn/"
                >http://www.customs.gov.cn/</a
              >
            </p>
          </div>
        </div> -->
        </div>
        <div class="xfz" v-show="8 === isShow">
          <div class="Box" ref="IMG">
            <div class="cont1">
              <img :src="Store.enterpriseHomePageItems[0].storeLogo" alt="" />
              <p>{{ CommodityDetails.manufacturer }}</p>
              <h3>
                －{{
                  Store.enterpriseHomePageItems[0].storeCommodityItems[0]
                    .retailPrice | Price
                }}
              </h3>
            </div>
            <div class="cont2">
              <ul style="margin-bottom: 17px">
                <li>商品编号</li>
                <li>单价</li>
                <li>数量</li>
                <li>小计</li>
              </ul>
              <ul>
                <li>品名</li>
                <li>规格</li>
                <li>单位</li>
                <li>批号</li>
                <li>厂家</li>
                <li>产地</li>
              </ul>
            </div>
            <div class="cont3">
              <ul>
                <li>{{ CommodityDetails.barcode }}</li>
                <li>
                  {{
                    Store.enterpriseHomePageItems[0].storeCommodityItems[0]
                      .retailPrice | Price
                  }}
                </li>
                <li>1.0</li>
                <li>
                  {{
                    Store.enterpriseHomePageItems[0].storeCommodityItems[0]
                      .retailPrice | Price
                  }}
                </li>
              </ul>
              <ul>
                <li class="name" :title="CommodityDetails.commodityName">
                  {{ CommodityDetails.commodityName }}
                </li>
                <li>{{ CommodityDetails.specsParameter }}</li>
                <li>{{ CommodityDetails.prickle }}</li>
                <li>批号：20200803</li>
              </ul>
              <ul style="margin-bottom: 0">
                <li class="storeName" :title="CommodityDetails.manufacturer">
                  {{ CommodityDetails.manufacturer }}
                </li>
                <li>{{ CommodityDetails.province }}</li>
              </ul>
            </div>
            <div class="cont4">
              <div>
                <p>当前状态</p>
                <span>支付成功</span>
              </div>
              <div>
                <p>商家</p>
                <a :href="manufacturer" target="_blank">{{
                  CommodityDetails.manufacturer
                }}</a>
              </div>
              <div>
                <p>支付时间</p>
                <span>{{ CommodityDetails.createTime | nowTime }}</span>
              </div>
              <div>
                <p>支付方式</p>
                <span>广州银行储蓄卡（1618）</span>
              </div>
              <div>
                <p>交易单号</p>
                <span>4200000837202101126383104318</span>
              </div>
              <div style="margin-bottom: 100px">
                <p>商户单号</p>
                <span>可在支持的商户扫码退款</span>
              </div>
              <img src="@/assets/images/xfz-.png" alt="" />
              <p style="font-size: 24px; margin-top: 5px; color: #333333">
                48956212354798622021320141
              </p>
            </div>
            <div class="cont5">
              <div>
                <p>电子保单</p>
                <span>1587588455464554</span>
              </div>
              <div>
                <p>保单机构</p>
                <a
                  href="http://www.guorenpcic.com/web/bdyz/index.jhtml"
                  target="_blank"
                  >国任保险</a
                >
              </div>
              <div>
                <p>有效时间</p>
                <span
                  >{{ CommodityDetails.createTime | nowDay }}至{{
                    CommodityDetails.createTime | oneYear
                  }}</span
                >
              </div>
              <div>
                <p>物流轨迹</p>
                <a @click="isShow = 3">商品全生命周期在线检索</a>
              </div>
            </div>
          </div>
          <div class="arrow-left arrow" @click="up">
            <img src="@/assets/images/Goods-left.png" />
          </div>
          <div class="arrow-right arrow" @click="down">
            <img src="@/assets/images/Goods-right.png" />
          </div>
        </div>
      </div>
    </div>
    <div v-if="store === 2">
      <div
        class="website"
        v-for="(a, index) in shoes"
        :key="index"
        v-show="index === isShow"
      >
        <img :src="a.trademark" alt="" />
        <div>
          <p>{{ a.cont }}官网查询链接：</p>
          <a :href="a.url" target="_blank">{{ a.url }}</a>
        </div>
      </div>
      <div class="website" v-show="7 === isShow">
        <img src="" alt="" />
        <div>
          <p>官网查询链接：</p>
          <a href="" target="_blank"></a>
        </div>
      </div>
      <div class="content_box">
        <div ref="nav" class="nav">
          <!-- <svg-icon
          icon-class="TrackBack-arrow-left"
          class="arrow"
          @click="leftclick"
        /> -->
          <div>
            <ul ref="ulWidth">
              <li
                v-for="(list, index) in shoes"
                :key="index"
                :class="[index === isShow ? 'onLi' : '']"
              >
                <svg-icon :icon-class="list.icon" />
                <p @click="OnLi(index)">{{ list.cont }}</p>
                <img src="@/assets/images/TrackBack-arrow.png" alt="" />
              </li>
              <li :class="[7 === isShow ? 'onLi' : '']">
                <svg-icon icon-class="xfz_11icon" />
                <p @click="OnLi(7)">消费者</p>
              </li>
            </ul>
          </div>
          <!-- <svg-icon
          icon-class="TrackBack-arrow-right"
          class="arrow"
          @click="rightclick"
        /> -->
        </div>
        <div
          class="content"
          v-for="(list, index) in shoes"
          :key="index"
          v-show="index === isShow"
        >
          <div
            class="imgBox"
            :class="[
              isShow === 1 ||
              isShow === 7 ||
              isShow === 3 ||
              isShow === 4 ||
              isShow === 5 ||
              isShow === 6
                ? 'imgBox-auto'
                : '',
            ]"
            ref="IMG"
          >
            <img
              :src="img"
              alt=""
              class="documents"
              v-for="(img, i) in list.img"
              :key="i"
              @click="goA(i)"
            />
          </div>
          <!-- <p
          @click="copy"
          :data-clipboard-text="list.odd"
          id="express_info_right"
        >
          复制
        </p> -->
          <div
            class="arrow-left arrow"
            @click="up"
            :class="[
              isShow === 4 || isShow === 7 ? 'arrow-qs' : '',
              isShow === 5 ? 'arrow-yh' : '',
            ]"
          >
            <img src="@/assets/images/Goods-left.png" />
          </div>
          <div
            class="arrow-right arrow"
            @click="down"
            :class="[
              isShow === 4 || isShow === 7 ? 'arrow-qs' : '',
              isShow === 5 ? 'arrow-yh' : '',
            ]"
          >
            <img src="@/assets/images/Goods-right.png" />
          </div>
          <!-- <div class="text">
          <div class="title">
            <img src="@/assets/images/tack-back-icon.png" alt="" />总署概况
          </div>
          <div class="cont">
            <h3>中华人民共和国海关总署</h3>
            <p>
              中华人民共和国海关总署是国务院直属机构，为正部级。负责全国海关工作、组织推动岸“大通关”
              建设、海关监管工作、进出口关税及其他税费征收管理、出入境卫生检疫和出入境动植及其产品检验
              检疫、进出口商品法定检验、海关风险管理、国家进出口货物贸易等海关统计、全国打走私综合治理
              工作、制定并组织实施海关科技发展规划以及实验室建设和技术保障规划、海关领域国合作与交流、
              垂直管理全国海关、完成党中央国务院交办的其他任务。中央纪委国家监委在海关总署驻纪检监察组。
            </p>
            <p>
              网址：<a href="http://www.customs.gov.cn/"
                >http://www.customs.gov.cn/</a
              >
            </p>
          </div>
        </div> -->
        </div>
        <div class="xfz" v-show="7 === isShow">
          <div class="Box" ref="IMG">
            <div class="cont1">
              <img :src="Store.enterpriseHomePageItems[0].storeLogo" alt="" />
              <p>{{ CommodityDetails.manufacturer }}</p>
              <h3>
                －{{
                  Store.enterpriseHomePageItems[0].storeCommodityItems[0]
                    .retailPrice | Price
                }}
              </h3>
            </div>
            <div class="cont2">
              <ul style="margin-bottom: 17px">
                <li>商品编号</li>
                <li>单价</li>
                <li>数量</li>
                <li>小计</li>
              </ul>
              <ul>
                <li>品名</li>
                <li>规格</li>
                <li>单位</li>
                <li>批号</li>
                <li>厂家</li>
                <li>产地</li>
              </ul>
            </div>
            <div class="cont3">
              <ul>
                <li>{{ CommodityDetails.barcode }}</li>
                <li>
                  {{
                    Store.enterpriseHomePageItems[0].storeCommodityItems[0]
                      .retailPrice | Price
                  }}
                </li>
                <li>1.0</li>
                <li>
                  {{
                    Store.enterpriseHomePageItems[0].storeCommodityItems[0]
                      .retailPrice | Price
                  }}
                </li>
              </ul>
              <ul>
                <li class="name" :title="CommodityDetails.commodityName">
                  {{ CommodityDetails.commodityName }}
                </li>
                <li>{{ CommodityDetails.specsParameter }}</li>
                <li>{{ CommodityDetails.prickle }}</li>
                <li>批号：20200803</li>
              </ul>
              <ul style="margin-bottom: 0">
                <li class="storeName" :title="CommodityDetails.manufacturer">
                  {{ CommodityDetails.manufacturer }}
                </li>
                <li>{{ CommodityDetails.province }}</li>
              </ul>
            </div>
            <div class="cont4">
              <div>
                <p>当前状态</p>
                <span>支付成功</span>
              </div>
              <div>
                <p>商家</p>
                <a :href="manufacturer" target="_blank">{{
                  CommodityDetails.manufacturer
                }}</a>
              </div>
              <div>
                <p>支付时间</p>
                <span>{{ CommodityDetails.createTime | nowTime }}</span>
              </div>
              <div>
                <p>支付方式</p>
                <span>广州银行储蓄卡（1618）</span>
              </div>
              <div>
                <p>交易单号</p>
                <span>4200000837202101126383104318</span>
              </div>
              <div style="margin-bottom: 100px">
                <p>商户单号</p>
                <span>可在支持的商户扫码退款</span>
              </div>
              <img src="@/assets/images/xfz-.png" alt="" />
              <p style="font-size: 24px; margin-top: 5px; color: #333333">
                48956212354798622021320141
              </p>
            </div>
            <div class="cont5">
              <div>
                <p>电子保单</p>
                <span>1587588455464554</span>
              </div>
              <div>
                <p>保单机构</p>
                <a
                  href="http://www.guorenpcic.com/web/bdyz/index.jhtml"
                  target="_blank"
                  >国任保险</a
                >
              </div>
              <div>
                <p>有效时间</p>
                <span
                  >{{ CommodityDetails.createTime | nowDay }}至{{
                    CommodityDetails.createTime | oneYear
                  }}</span
                >
              </div>
              <div>
                <p>物流轨迹</p>
                <a @click="isShow = 3">商品全生命周期在线检索</a>
              </div>
            </div>
          </div>
          <div class="arrow-left arrow" @click="up">
            <img src="@/assets/images/Goods-left.png" />
          </div>
          <div class="arrow-right arrow" @click="down">
            <img src="@/assets/images/Goods-right.png" />
          </div>
        </div>
      </div>
    </div>
    <div v-if="store === 3">
      <div
        class="website"
        v-for="(a, index) in clothes"
        :key="index"
        v-show="index === isShow"
      >
        <img :src="a.trademark" alt="" />
        <div>
          <p>{{ a.cont }}官网查询链接：</p>
          <a :href="a.url" target="_blank">{{ a.url }}</a>
        </div>
      </div>
      <div class="website" v-show="9 === isShow">
        <img src="" alt="" />
        <div>
          <p>官网查询链接：</p>
          <a href="" target="_blank"></a>
        </div>
      </div>
      <div class="content_box">
        <div ref="nav" class="nav">
          <!-- <svg-icon
          icon-class="TrackBack-arrow-left"
          class="arrow"
          @click="leftclick"
        /> -->
          <div>
            <ul ref="ulWidth">
              <li
                v-for="(list, index) in clothes"
                :key="index"
                :class="[index === isShow ? 'onLi' : '']"
              >
                <svg-icon :icon-class="list.icon" />
                <p @click="OnLi(index)">{{ list.cont }}</p>
                <img src="@/assets/images/TrackBack-arrow.png" alt="" />
              </li>
              <li :class="[9 === isShow ? 'onLi' : '']">
                <svg-icon icon-class="xfz_11icon" />
                <p @click="OnLi(9)">消费者</p>
              </li>
            </ul>
          </div>
          <!-- <svg-icon
          icon-class="TrackBack-arrow-right"
          class="arrow"
          @click="rightclick"
        /> -->
        </div>
        <div
          class="content"
          v-for="(list, index) in clothes"
          :key="index"
          v-show="index === isShow"
        >
          <div
            class="imgBox"
            :class="[
              isShow === 0 ||
              isShow === 1 ||
              isShow === 2 ||
              isShow === 3 ||
              isShow === 7 ||
              isShow === 5 ||
              isShow === 6
                ? 'imgBox-auto'
                : '',
            ]"
            ref="IMG"
          >
            <img
              :src="img"
              alt=""
              class="documents"
              v-for="(img, i) in list.img"
              :key="i"
              @click="goA(i)"
            />
          </div>
          <!-- <p
          @click="copy"
          :data-clipboard-text="list.odd"
          id="express_info_right"
        >
          复制
        </p> -->
          <div
            class="arrow-left arrow"
            @click="up"
            :class="[
              isShow === 0 || isShow === 1 || isShow === 2 || isShow === 6
                ? 'arrow-qs'
                : '',
              isShow === 7 ? 'arrow-yh' : '',
            ]"
          >
            <img src="@/assets/images/Goods-left.png" />
          </div>
          <div
            class="arrow-right arrow"
            @click="down"
            :class="[
              isShow === 0 || isShow === 1 || isShow === 2 || isShow === 6
                ? 'arrow-qs'
                : '',
              isShow === 7 ? 'arrow-yh' : '',
            ]"
          >
            <img src="@/assets/images/Goods-right.png" />
          </div>
          <!-- <div class="text">
          <div class="title">
            <img src="@/assets/images/tack-back-icon.png" alt="" />总署概况
          </div>
          <div class="cont">
            <h3>中华人民共和国海关总署</h3>
            <p>
              中华人民共和国海关总署是国务院直属机构，为正部级。负责全国海关工作、组织推动岸“大通关”
              建设、海关监管工作、进出口关税及其他税费征收管理、出入境卫生检疫和出入境动植及其产品检验
              检疫、进出口商品法定检验、海关风险管理、国家进出口货物贸易等海关统计、全国打走私综合治理
              工作、制定并组织实施海关科技发展规划以及实验室建设和技术保障规划、海关领域国合作与交流、
              垂直管理全国海关、完成党中央国务院交办的其他任务。中央纪委国家监委在海关总署驻纪检监察组。
            </p>
            <p>
              网址：<a href="http://www.customs.gov.cn/"
                >http://www.customs.gov.cn/</a
              >
            </p>
          </div>
        </div> -->
        </div>
        <div class="xfz" v-show="9 === isShow">
          <div class="Box" ref="IMG">
            <div class="cont1">
              <img :src="Store.enterpriseHomePageItems[0].storeLogo" alt="" />
              <p>{{ CommodityDetails.manufacturer }}</p>
              <h3>
                －{{
                  Store.enterpriseHomePageItems[0].storeCommodityItems[0]
                    .retailPrice | Price
                }}
              </h3>
            </div>
            <div class="cont2">
              <ul style="margin-bottom: 17px">
                <li>商品编号</li>
                <li>单价</li>
                <li>数量</li>
                <li>小计</li>
              </ul>
              <ul>
                <li>品名</li>
                <li>规格</li>
                <li>单位</li>
                <li>批号</li>
                <li>厂家</li>
                <li>产地</li>
              </ul>
            </div>
            <div class="cont3">
              <ul>
                <li>{{ CommodityDetails.barcode }}</li>
                <li>
                  {{
                    Store.enterpriseHomePageItems[0].storeCommodityItems[0]
                      .retailPrice | Price
                  }}
                </li>
                <li>1.0</li>
                <li>
                  {{
                    Store.enterpriseHomePageItems[0].storeCommodityItems[0]
                      .retailPrice | Price
                  }}
                </li>
              </ul>
              <ul>
                <li class="name" :title="CommodityDetails.commodityName">
                  {{ CommodityDetails.commodityName }}
                </li>
                <li>{{ CommodityDetails.specsParameter }}</li>
                <li>{{ CommodityDetails.prickle }}</li>
                <li>批号：20200803</li>
              </ul>
              <ul style="margin-bottom: 0">
                <li class="storeName" :title="CommodityDetails.manufacturer">
                  {{ CommodityDetails.manufacturer }}
                </li>
                <li>{{ CommodityDetails.province }}</li>
              </ul>
            </div>
            <div class="cont4">
              <div>
                <p>当前状态</p>
                <span>支付成功</span>
              </div>
              <div>
                <p>商家</p>
                <a :href="manufacturer" target="_blank">{{
                  CommodityDetails.manufacturer
                }}</a>
              </div>
              <div>
                <p>支付时间</p>
                <span>{{ CommodityDetails.createTime | nowTime }}</span>
              </div>
              <div>
                <p>支付方式</p>
                <span>广州银行储蓄卡（1618）</span>
              </div>
              <div>
                <p>交易单号</p>
                <span>4200000837202101126383104318</span>
              </div>
              <div style="margin-bottom: 100px">
                <p>商户单号</p>
                <span>可在支持的商户扫码退款</span>
              </div>
              <img src="@/assets/images/xfz-.png" alt="" />
              <p style="font-size: 24px; margin-top: 5px; color: #333333">
                48956212354798622021320141
              </p>
            </div>
            <div class="cont5">
              <div>
                <p>电子保单</p>
                <span>1587588455464554</span>
              </div>
              <div>
                <p>保单机构</p>
                <a
                  href="http://www.guorenpcic.com/web/bdyz/index.jhtml"
                  target="_blank"
                  >国任保险</a
                >
              </div>
              <div>
                <p>有效时间</p>
                <span
                  >{{ CommodityDetails.createTime | nowDay }}至{{
                    CommodityDetails.createTime | oneYear
                  }}</span
                >
              </div>
              <div>
                <p>物流轨迹</p>
                <a @click="isShow = 5">商品全生命周期在线检索</a>
              </div>
            </div>
          </div>
          <div class="arrow-left arrow" @click="up">
            <img src="@/assets/images/Goods-left.png" />
          </div>
          <div class="arrow-right arrow" @click="down">
            <img src="@/assets/images/Goods-right.png" />
          </div>
        </div>
      </div>
    </div>
    <div v-if="store === 4">
      <div
        class="website"
        v-for="(a, index) in other"
        :key="index"
        v-show="index === isShow"
      >
        <img :src="a.trademark" alt="" />
        <div>
          <p>{{ a.cont }}官网查询链接：</p>
          <a :href="a.url" target="_blank">{{ a.url }}</a>
        </div>
      </div>
      <div class="content_box">
        <div ref="nav" class="nav">
          <!-- <svg-icon
          icon-class="TrackBack-arrow-left"
          class="arrow"
          @click="leftclick"
        /> -->
          <div>
            <ul ref="ulWidth">
              <li
                v-for="(list, index) in other"
                :key="index"
                :class="[index === isShow ? 'onLi' : '']"
              >
                <svg-icon :icon-class="list.icon" />
                <p @click="OnLi(index)">{{ list.cont }}</p>
                <img src="@/assets/images/TrackBack-arrow.png" alt="" />
              </li>
            </ul>
          </div>
          <!-- <svg-icon
          icon-class="TrackBack-arrow-right"
          class="arrow"
          @click="rightclick"
        /> -->
        </div>
        <div
          class="content"
          v-for="(list, index) in other"
          :key="index"
          v-show="index === isShow"
        >
          <div class="imgBox-auto" ref="IMG">
            <img
              :src="img"
              alt=""
              class="documents"
              v-for="(img, i) in list.img"
              :key="i"
            />
          </div>
          <!-- <p
          @click="copy"
          :data-clipboard-text="list.odd"
          id="express_info_right"
        >
          复制
        </p> -->
          <div class="arrow-left arrow arrow-other" @click="up">
            <img src="@/assets/images/Goods-left.png" />
          </div>
          <div class="arrow-right arrow arrow-other" @click="down">
            <img src="@/assets/images/Goods-right.png" />
          </div>
          <!-- <div class="text">
          <div class="title">
            <img src="@/assets/images/tack-back-icon.png" alt="" />总署概况
          </div>
          <div class="cont">
            <h3>中华人民共和国海关总署</h3>
            <p>
              中华人民共和国海关总署是国务院直属机构，为正部级。负责全国海关工作、组织推动岸“大通关”
              建设、海关监管工作、进出口关税及其他税费征收管理、出入境卫生检疫和出入境动植及其产品检验
              检疫、进出口商品法定检验、海关风险管理、国家进出口货物贸易等海关统计、全国打走私综合治理
              工作、制定并组织实施海关科技发展规划以及实验室建设和技术保障规划、海关领域国合作与交流、
              垂直管理全国海关、完成党中央国务院交办的其他任务。中央纪委国家监委在海关总署驻纪检监察组。
            </p>
            <p>
              网址：<a href="http://www.customs.gov.cn/"
                >http://www.customs.gov.cn/</a
              >
            </p>
          </div>
        </div> -->
        </div>
      </div>
    </div>
    <div v-if="store === 5">
      <div
        class="website"
        v-for="(a, index) in cy"
        :key="index"
        v-show="index === isShow"
      >
        <img :src="a.trademark" alt="" />
        <div>
          <p>{{ a.cont }}官网查询链接：</p>
          <a :href="a.url" target="_blank">{{ a.url }}</a>
        </div>
      </div>
      <div class="website" v-show="9 === isShow">
        <img src="" alt="" />
        <div>
          <p>官网查询链接：</p>
          <a href="" target="_blank"></a>
        </div>
      </div>
      <div class="content_box">
        <div ref="nav" class="nav">
          <div>
            <ul ref="ulWidth">
              <li
                v-for="(list, index) in cy"
                :key="index"
                :class="[index === isShow ? 'onLi' : '']"
              >
                <svg-icon :icon-class="list.icon" />
                <p @click="OnLi(index)">{{ list.cont }}</p>
                <img src="@/assets/images/TrackBack-arrow.png" alt="" />
              </li>
              <li :class="[9 === isShow ? 'onLi' : '']">
                <svg-icon icon-class="xfz_11icon" />
                <p @click="OnLi(9)">消费者</p>
              </li>
            </ul>
          </div>
        </div>
        <div
          class="content"
          v-for="(list, index) in cy"
          :key="index"
          v-show="index === isShow"
        >
          <div
            class="imgBox"
            ref="IMG"
            :class="[
              isShow === 0 ||
              isShow === 1 ||
              isShow === 3 ||
              isShow === 5 ||
              isShow === 7 ||
              isShow === 6 ||
              isShow === 8
                ? 'imgBox-auto'
                : '',
            ]"
          >
            <img
              :src="img"
              alt=""
              class="documents"
              v-for="(img, i) in list.img"
              :key="i"
            />
          </div>
          <div
            class="arrow-left arrow"
            @click="up"
            :class="[
              isShow === 1 || isShow === 9 ? 'arrow-qs' : '',
              isShow === 0 ? 'arrow-tp' : '',
              isShow === 6 ? 'arrow-tp2' : '',
              isShow === 7 ? 'arrow-yh' : '',
            ]"
          >
            <img src="@/assets/images/Goods-left.png" />
          </div>
          <div
            class="arrow-right arrow"
            @click="down"
            :class="[
              isShow === 1 || isShow === 9 ? 'arrow-qs' : '',

              isShow === 0 ? 'arrow-tp' : '',
              isShow === 6 ? 'arrow-tp2' : '',
              isShow === 7 ? 'arrow-yh' : '',
            ]"
          >
            <img src="@/assets/images/Goods-right.png" />
          </div>
        </div>
        <div class="xfz" v-show="9 === isShow">
          <div class="Box" ref="IMG">
            <div class="cont1">
              <img :src="Store.enterpriseHomePageItems[0].storeLogo" alt="" />
              <p>{{ CommodityDetails.manufacturer }}</p>
              <h3>
                －{{
                  Store.enterpriseHomePageItems[0].storeCommodityItems[0]
                    .retailPrice | Price
                }}
              </h3>
            </div>
            <div class="cont2">
              <ul style="margin-bottom: 17px">
                <li>商品编号</li>
                <li>单价</li>
                <li>数量</li>
                <li>小计</li>
              </ul>
              <ul>
                <li>品名</li>
                <li>规格</li>
                <li>单位</li>
                <li>批号</li>
                <li>厂家</li>
                <li>产地</li>
              </ul>
            </div>
            <div class="cont3">
              <ul>
                <li>{{ CommodityDetails.barcode }}</li>
                <li>
                  {{
                    Store.enterpriseHomePageItems[0].storeCommodityItems[0]
                      .retailPrice | Price
                  }}
                </li>
                <li>1.0</li>
                <li>
                  {{
                    Store.enterpriseHomePageItems[0].storeCommodityItems[0]
                      .retailPrice | Price
                  }}
                </li>
              </ul>
              <ul>
                <li class="name" :title="CommodityDetails.commodityName">
                  {{ CommodityDetails.commodityName }}
                </li>
                <li>{{ CommodityDetails.specsParameter }}</li>
                <li>{{ CommodityDetails.prickle }}</li>
                <li>批号：20200803</li>
              </ul>
              <ul style="margin-bottom: 0">
                <li class="storeName" :title="CommodityDetails.manufacturer">
                  {{ CommodityDetails.manufacturer }}
                </li>
                <li>{{ CommodityDetails.province }}</li>
              </ul>
            </div>
            <div class="cont4">
              <div>
                <p>当前状态</p>
                <span>支付成功</span>
              </div>
              <div>
                <p>商家</p>
                <a :href="manufacturer" target="_blank">{{
                  CommodityDetails.manufacturer
                }}</a>
              </div>
              <div>
                <p>支付时间</p>
                <span>{{ CommodityDetails.createTime | nowTime }}</span>
              </div>
              <div>
                <p>支付方式</p>
                <span>广州银行储蓄卡（1618）</span>
              </div>
              <div>
                <p>交易单号</p>
                <span>4200000837202101126383104318</span>
              </div>
              <div style="margin-bottom: 100px">
                <p>商户单号</p>
                <span>可在支持的商户扫码退款</span>
              </div>
              <img src="@/assets/images/xfz-.png" alt="" />
              <p style="font-size: 24px; margin-top: 5px; color: #333333">
                48956212354798622021320141
              </p>
            </div>
            <div class="cont5">
              <div>
                <p>电子保单</p>
                <span>1587588455464554</span>
              </div>
              <div>
                <p>保单机构</p>
                <a
                  href="http://www.guorenpcic.com/web/bdyz/index.jhtml"
                  target="_blank"
                  >国任保险</a
                >
              </div>
              <div>
                <p>有效时间</p>
                <span
                  >{{ CommodityDetails.createTime | nowDay }}至{{
                    CommodityDetails.createTime | oneYear
                  }}</span
                >
              </div>
              <div>
                <p>物流轨迹</p>
                <a @click="isShow = 5">商品全生命周期在线检索</a>
              </div>
            </div>
          </div>
          <div class="arrow-left arrow" @click="up">
            <img src="@/assets/images/Goods-left.png" />
          </div>
          <div class="arrow-right arrow" @click="down">
            <img src="@/assets/images/Goods-right.png" />
          </div>
        </div>
      </div>
    </div>
    <div v-if="store === 6">
      <div
        class="website"
        v-for="(a, index) in zywy"
        :key="index"
        v-show="index === isShow"
      >
        <img :src="a.trademark" alt="" />
        <div>
          <p>{{ a.cont }}官网查询链接：</p>
          <a :href="a.url" target="_blank">{{ a.url }}</a>
        </div>
      </div>
      <div class="website" v-show="9 === isShow">
        <img src="" alt="" />
        <div>
          <p>官网查询链接：</p>
          <a href="" target="_blank"></a>
        </div>
      </div>
      <div class="content_box">
        <div ref="nav" class="nav">
          <div>
            <ul ref="ulWidth">
              <li
                v-for="(list, index) in zywy"
                :key="index"
                :class="[index === isShow ? 'onLi' : '']"
              >
                <svg-icon :icon-class="list.icon" />
                <p @click="OnLi(index)">{{ list.cont }}</p>
                <img src="@/assets/images/TrackBack-arrow.png" alt="" />
              </li>
              <li :class="[9 === isShow ? 'onLi' : '']">
                <svg-icon icon-class="xfz_11icon" />
                <p @click="OnLi(9)">消费者</p>
              </li>
            </ul>
          </div>
        </div>
        <div
          class="content"
          v-for="(list, index) in zywy"
          :key="index"
          v-show="index === isShow"
        >
          <div
            class="imgBox"
            ref="IMG"
            :class="[
              isShow === 1 ||
              isShow === 3 ||
              isShow === 4 ||
              isShow === 5 ||
              isShow === 6 ||
              isShow === 7 ||
              isShow === 8 ||
              isShow === 9
                ? 'imgBox-auto'
                : '',
            ]"
          >
            <img
              :src="img"
              alt=""
              class="documents"
              v-for="(img, i) in list.img"
              :key="i"
            />
          </div>
          <div
            class="arrow-left arrow"
            @click="up"
            :class="[
              isShow === 4 || isShow === 5 || isShow === 6 || isShow === 9
                ? 'arrow-qs'
                : '',

              isShow === 7 ? 'arrow-yh' : '',
            ]"
          >
            <img src="@/assets/images/Goods-left.png" />
          </div>
          <div
            class="arrow-right arrow"
            @click="down"
            :class="[
              isShow === 4 || isShow === 5 || isShow === 6 || isShow === 9
                ? 'arrow-qs'
                : '',

              isShow === 7 ? 'arrow-yh' : '',
            ]"
          >
            <img src="@/assets/images/Goods-right.png" />
          </div>
        </div>
        <div class="xfz" v-show="9 === isShow">
          <div class="Box" ref="IMG">
            <div class="cont1">
              <img :src="Store.enterpriseHomePageItems[0].storeLogo" alt="" />
              <p>{{ CommodityDetails.manufacturer }}</p>
              <h3>
                －{{
                  Store.enterpriseHomePageItems[0].storeCommodityItems[0]
                    .retailPrice | Price
                }}
              </h3>
            </div>
            <div class="cont2">
              <ul style="margin-bottom: 17px">
                <li>商品编号</li>
                <li>单价</li>
                <li>数量</li>
                <li>小计</li>
              </ul>
              <ul>
                <li>品名</li>
                <li>规格</li>
                <li>单位</li>
                <li>批号</li>
                <li>厂家</li>
                <li>产地</li>
              </ul>
            </div>
            <div class="cont3">
              <ul>
                <li>{{ CommodityDetails.barcode }}</li>
                <li>
                  {{
                    Store.enterpriseHomePageItems[0].storeCommodityItems[0]
                      .retailPrice | Price
                  }}
                </li>
                <li>1.0</li>
                <li>
                  {{
                    Store.enterpriseHomePageItems[0].storeCommodityItems[0]
                      .retailPrice | Price
                  }}
                </li>
              </ul>
              <ul>
                <li class="name" :title="CommodityDetails.commodityName">
                  {{ CommodityDetails.commodityName }}
                </li>
                <li>{{ CommodityDetails.specsParameter }}</li>
                <li>{{ CommodityDetails.prickle }}</li>
                <li>批号：20200803</li>
              </ul>
              <ul style="margin-bottom: 0">
                <li class="storeName" :title="CommodityDetails.manufacturer">
                  {{ CommodityDetails.manufacturer }}
                </li>
                <li>{{ CommodityDetails.province }}</li>
              </ul>
            </div>
            <div class="cont4">
              <div>
                <p>当前状态</p>
                <span>支付成功</span>
              </div>
              <div>
                <p>商家</p>
                <a :href="manufacturer" target="_blank">{{
                  CommodityDetails.manufacturer
                }}</a>
              </div>
              <div>
                <p>支付时间</p>
                <span>{{ CommodityDetails.createTime | nowTime }}</span>
              </div>
              <div>
                <p>支付方式</p>
                <span>广州银行储蓄卡（1618）</span>
              </div>
              <div>
                <p>交易单号</p>
                <span>4200000837202101126383104318</span>
              </div>
              <div style="margin-bottom: 100px">
                <p>商户单号</p>
                <span>可在支持的商户扫码退款</span>
              </div>
              <img src="@/assets/images/xfz-.png" alt="" />
              <p style="font-size: 24px; margin-top: 5px; color: #333333">
                48956212354798622021320141
              </p>
            </div>
            <div class="cont5">
              <div>
                <p>电子保单</p>
                <span>1587588455464554</span>
              </div>
              <div>
                <p>保单机构</p>
                <a
                  href="http://www.guorenpcic.com/web/bdyz/index.jhtml"
                  target="_blank"
                  >国任保险</a
                >
              </div>
              <div>
                <p>有效时间</p>
                <span
                  >{{ CommodityDetails.createTime | nowDay }}至{{
                    CommodityDetails.createTime | oneYear
                  }}</span
                >
              </div>
              <div>
                <p>物流轨迹</p>
                <a @click="isShow = 3">商品全生命周期在线检索</a>
              </div>
            </div>
          </div>
          <div class="arrow-left arrow" @click="up">
            <img src="@/assets/images/Goods-left.png" />
          </div>
          <div class="arrow-right arrow" @click="down">
            <img src="@/assets/images/Goods-right.png" />
          </div>
        </div>
      </div>
    </div>
    <div v-if="store === 7">
      <div
        class="website"
        v-for="(a, index) in mlx"
        :key="index"
        v-show="index === isShow"
      >
        <img :src="a.trademark" alt="" />
        <div>
          <p>{{ a.cont }}官网查询链接：</p>
          <a :href="a.url" target="_blank">{{ a.url }}</a>
        </div>
      </div>
      <div class="website" v-show="11 === isShow">
        <img src="" alt="" />
        <div>
          <p>官网查询链接：</p>
          <a href="" target="_blank"></a>
        </div>
      </div>
      <div class="content_box">
        <div ref="nav" class="nav">
          <div>
            <ul ref="ulWidth">
              <li
                v-for="(list, index) in mlx"
                :key="index"
                :class="[index === isShow ? 'onLi' : '']"
              >
                <svg-icon :icon-class="list.icon" />
                <p @click="OnLi(index)">{{ list.cont }}</p>
                <img src="@/assets/images/TrackBack-arrow.png" alt="" />
              </li>
              <li :class="[11 === isShow ? 'onLi' : '']">
                <svg-icon icon-class="xfz_11icon" />
                <p @click="OnLi(11)">消费者</p>
              </li>
            </ul>
          </div>
        </div>
        <div
          class="content"
          v-for="(list, index) in mlx"
          :key="index"
          v-show="index === isShow"
        >
          <div
            class="imgBox"
            ref="IMG"
            :class="[
              isShow === 5 ||
              isShow === 11 ||
              isShow === 7 ||
              isShow === 8 ||
              isShow === 9 ||
              isShow === 10
                ? 'imgBox-auto'
                : '',
            ]"
          >
            <img
              :src="img"
              alt=""
              class="documents"
              v-for="(img, i) in list.img"
              :key="i"
            />
          </div>
          <div
            class="arrow-left arrow"
            @click="up"
            :class="[
              isShow === 8 || isShow === 11 ? 'arrow-qs' : '',
              isShow === 9 ? 'arrow-yh' : '',
            ]"
          >
            <img src="@/assets/images/Goods-left.png" />
          </div>
          <div
            class="arrow-right arrow"
            @click="down"
            :class="[
              isShow === 8 || isShow === 11 ? 'arrow-qs' : '',
              isShow === 9 ? 'arrow-yh' : '',
            ]"
          >
            <img src="@/assets/images/Goods-right.png" />
          </div>
        </div>
        <div class="xfz" v-show="11 === isShow">
          <div class="Box" ref="IMG">
            <div class="cont1">
              <img :src="Store.enterpriseHomePageItems[0].storeLogo" alt="" />
              <p>{{ CommodityDetails.manufacturer }}</p>
              <h3>
                －{{
                  Store.enterpriseHomePageItems[0].storeCommodityItems[0]
                    .retailPrice | Price
                }}
              </h3>
            </div>
            <div class="cont2">
              <ul style="margin-bottom: 17px">
                <li>商品编号</li>
                <li>单价</li>
                <li>数量</li>
                <li>小计</li>
              </ul>
              <ul>
                <li>品名</li>
                <li>规格</li>
                <li>单位</li>
                <li>批号</li>
                <li>厂家</li>
                <li>产地</li>
              </ul>
            </div>
            <div class="cont3">
              <ul>
                <li>{{ CommodityDetails.barcode }}</li>
                <li>
                  {{
                    Store.enterpriseHomePageItems[0].storeCommodityItems[0]
                      .retailPrice | Price
                  }}
                </li>
                <li>1.0</li>
                <li>
                  {{
                    Store.enterpriseHomePageItems[0].storeCommodityItems[0]
                      .retailPrice | Price
                  }}
                </li>
              </ul>
              <ul>
                <li class="name" :title="CommodityDetails.commodityName">
                  {{ CommodityDetails.commodityName }}
                </li>
                <li>{{ CommodityDetails.specsParameter }}</li>
                <li>{{ CommodityDetails.prickle }}</li>
                <li>批号：20200803</li>
              </ul>
              <ul style="margin-bottom: 0">
                <li class="storeName" :title="CommodityDetails.manufacturer">
                  {{ CommodityDetails.manufacturer }}
                </li>
                <li>{{ CommodityDetails.province }}</li>
              </ul>
            </div>
            <div class="cont4">
              <div>
                <p>当前状态</p>
                <span>支付成功</span>
              </div>
              <div>
                <p>商家</p>
                <a :href="manufacturer" target="_blank">{{
                  CommodityDetails.manufacturer
                }}</a>
              </div>
              <div>
                <p>支付时间</p>
                <span>{{ CommodityDetails.createTime | nowTime }}</span>
              </div>
              <div>
                <p>支付方式</p>
                <span>广州银行储蓄卡（1618）</span>
              </div>
              <div>
                <p>交易单号</p>
                <span>4200000837202101126383104318</span>
              </div>
              <div style="margin-bottom: 100px">
                <p>商户单号</p>
                <span>可在支持的商户扫码退款</span>
              </div>
              <img src="@/assets/images/xfz-.png" alt="" />
              <p style="font-size: 24px; margin-top: 5px; color: #333333">
                48956212354798622021320141
              </p>
            </div>
            <div class="cont5">
              <div>
                <p>电子保单</p>
                <span>1587588455464554</span>
              </div>
              <div>
                <p>保单机构</p>
                <a
                  href="http://www.guorenpcic.com/web/bdyz/index.jhtml"
                  target="_blank"
                  >国任保险</a
                >
              </div>
              <div>
                <p>有效时间</p>
                <span
                  >{{ CommodityDetails.createTime | nowDay }}至{{
                    CommodityDetails.createTime | oneYear
                  }}</span
                >
              </div>
              <div>
                <p>物流轨迹</p>
                <a @click="isShow = 7">商品全生命周期在线检索</a>
              </div>
            </div>
          </div>
          <div class="arrow-left arrow" @click="up">
            <img src="@/assets/images/Goods-left.png" />
          </div>
          <div class="arrow-right arrow" @click="down">
            <img src="@/assets/images/Goods-right.png" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { formatDate, formatDay2 } from "@/utils/validate";

export default {
  name: "track-back",
  data() {
    return {
      li: [
        {
          cont: "消防认证",
          img: [require("@/assets/xf/01.png")],
          // odd: "80025668823062662003",
          url: "http://www.cccf.com.cn",
          icon: "zz_01icon",
          trademark: require("@/assets/xf/xf-logo.png"),
        },
        {
          cont: "赋码公司",
          img: [require("@/assets/images/fm.png")],
          url: "http://www.gdlasertech.com",
          icon: "fm_05icon",
          trademark: require("@/assets/images/fmgs-logo.png"),
        },
        {
          cont: "碳足迹",
          img: [
            require("@/assets/images/tzj1.png"),
            require("@/assets/images/tzj2.png"),
            require("@/assets/images/tzj3.png"),
            require("@/assets/images/tzj4.png"),
          ],
          odd: "53202111031212305",
          url: "https://www.cti-cert.com",
          icon: "jc_03icon",
          trademark:
            "https://www.cti-cert.com/upload/2021-03/161595002496180000.svg",
        },
        {
          cont: "物流",
          img: [require("@/assets/images/wl.png")],
          url: "https://www.ky-express.com",
          icon: "wl_06icon",
          trademark: require("@/assets/images/wl-logo.png"),
        },
        {
          cont: "销售",
          img: [require("@/assets/images/qs.png")],
          odd: "53202111031212305",
          url: "",
          icon: "xs_07icon",
          // trademark: require("@/assets/images/xs-logo.png"),
        },
        {
          cont: "保险",
          img: [require("@/assets/xf/bx.jpg")],
          odd: "53202111031212305",
          url: "http://www.guorenpcic.com/web/bdyz/index.jhtml",
          icon: "bx_10icon",
          trademark: require("@/assets/images/bx-logo.png"),
        },
        {
          cont: "银行",
          img: [require("@/assets/xf/yh.png")],
          url: "https://www.boc.cn",
          icon: "yh_09icon",
          trademark: require("@/assets/images/yh-logo.png"),
        },
      ],
      xf: [
        {
          cont: "消防认证",
          img: [require("@/assets/images/qs.png")],
          // odd: "80025668823062662003",
          url: "http://www.cccf.com.cn",
          icon: "zz_01icon",
          trademark: require("@/assets/xf/xf-logo.png"),
        },
        {
          cont: "赋码公司",
          img: [require("@/assets/images/fm.png")],
          url: "http://www.gdlasertech.com",
          icon: "fm_05icon",
          trademark: require("@/assets/images/fmgs-logo.png"),
        },
        {
          cont: "碳足迹",
          img: [
            require("@/assets/images/tzj1.png"),
            require("@/assets/images/tzj2.png"),
            require("@/assets/images/tzj3.png"),
            require("@/assets/images/tzj4.png"),
          ],
          odd: "53202111031212305",
          url: "https://www.cti-cert.com",
          icon: "jc_03icon",
          trademark:
            "https://www.cti-cert.com/upload/2021-03/161595002496180000.svg",
        },
        {
          cont: "物流",
          img: [require("@/assets/images/wl.png")],
          url: "https://www.ky-express.com",
          icon: "wl_06icon",
          trademark: require("@/assets/images/wl-logo.png"),
        },
        {
          cont: "销售",
          img: [require("@/assets/images/qs.png")],
          odd: "53202111031212305",
          url: "",
          icon: "xs_07icon",
          // trademark: require("@/assets/images/xs-logo.png"),
        },
        {
          cont: "保险",
          img: [require("@/assets/xf/bx.jpg")],
          odd: "53202111031212305",
          url: "http://www.guorenpcic.com/web/bdyz/index.jhtml",
          icon: "bx_10icon",
          trademark: require("@/assets/images/bx-logo.png"),
        },
        {
          cont: "银行",
          img: [require("@/assets/xf/yh.png")],
          url: "https://www.boc.cn",
          icon: "yh_09icon",
          trademark: require("@/assets/images/yh-logo.png"),
        },
      ],
      shoes: [
        {
          cont: "色牢度甲醛含量PH值可分解致癌芳香胺染料检测报告",
          img: [require("@/assets/qd/jcbg.png")],
          url: "http://www.zaiq.org.cn/",
          odd: "RK202103061006",
          icon: "jc_03icon",
          trademark: require("@/assets/qd/jcbg-logo.png"),
        },
        {
          cont: "赋码公司",
          img: [require("@/assets/images/fm.png")],
          url: "http://www.gdlasertech.com",
          icon: "fm_05icon",
          trademark: require("@/assets/images/fmgs-logo.png"),
        },
        {
          cont: "碳足迹",
          img: [
            require("@/assets/images/tzj1.png"),
            require("@/assets/images/tzj2.png"),
            require("@/assets/images/tzj3.png"),
            require("@/assets/images/tzj4.png"),
          ],
          odd: "53202111031212305",
          url: "https://www.cti-cert.com",
          icon: "jc_03icon",
          trademark:
            "https://www.cti-cert.com/upload/2021-03/161595002496180000.svg",
        },
        {
          cont: "物流",
          img: [require("@/assets/images/wl.png")],
          odd: "FD54045 / 76F01",
          url: "https://www.ky-express.com",
          icon: "wl_06icon",
          trademark: require("@/assets/images/wl-logo.png"),
        },
        {
          cont: "终端门店",
          img: [require("@/assets/images/qs.png")],
          // url: "https://www.crv.com.cn",
          icon: "ls_08icon",
          // trademark: require("@/assets/images/xs-logo.png"),
        },
        {
          cont: "银行",
          img: [require("@/assets/qd/yh.png")],
          url: "https://www.boc.cn",
          icon: "yh_09icon",
          trademark: require("@/assets/images/yh-logo.png"),
        },
        {
          cont: "保险",
          img: [require("@/assets/qd/bx.jpg")],
          url: "http://www.guorenpcic.com/web/bdyz/index.jhtml",
          icon: "bx_10icon",
          trademark: require("@/assets/images/bx-logo.png"),
        },
      ],
      clothes: [
        {
          cont: "服装有毒有害物质检测报告",
          img: [require("@/assets/images/qs.png")],
          // url: "http://report.ponytest.com/",
          odd: "RK202103061006",
          icon: "jc_03icon",
          // trademark: require("@/assets/images/pony-logo.png"),
        },
        {
          cont: "服装物理机械安全性能检测报告",
          img: [require("@/assets/images/qs.png")],
          // url: "http://report.ponytest.com/",
          odd: "RK202103061006",
          icon: "jc_03icon",
          // trademark: require("@/assets/images/pony-logo.png"),
        },
        {
          cont: "服装外观质量检测报告",
          img: [require("@/assets/images/qs.png")],
          // url: "http://report.ponytest.com/",
          odd: "RK202103061006",
          icon: "jc_03icon",
          // trademark: require("@/assets/images/pony-logo.png"),
        },
        {
          cont: "赋码公司",
          img: [require("@/assets/images/fm.png")],
          url: "http://www.gdlasertech.com",
          icon: "fm_05icon",
          trademark: require("@/assets/images/fmgs-logo.png"),
        },
        {
          cont: "碳足迹",
          img: [
            require("@/assets/images/tzj1.png"),
            require("@/assets/images/tzj2.png"),
            require("@/assets/images/tzj3.png"),
            require("@/assets/images/tzj4.png"),
          ],
          odd: "53202111031212305",
          url: "https://www.cti-cert.com",
          icon: "jc_03icon",
          trademark:
            "https://www.cti-cert.com/upload/2021-03/161595002496180000.svg",
        },
        {
          cont: "物流",
          img: [require("@/assets/images/wl.png")],
          odd: "FD54045 / 76F01",
          url: "https://www.ky-express.com",
          icon: "wl_06icon",
          trademark: require("@/assets/images/wl-logo.png"),
        },
        {
          cont: "终端门店",
          img: [require("@/assets/images/qs.png")],
          odd: "53202111031212305",
          // url: "https://www.crv.com.cn",
          icon: "ls_08icon",
          // trademark: require("@/assets/images/xs-logo.png"),
        },
        {
          cont: "银行",
          img: [require("@/assets/qd/yh.png")],
          odd: "53202111031212305",
          url: "https://www.boc.cn",
          icon: "yh_09icon",
          trademark: require("@/assets/images/yh-logo.png"),
        },
        {
          cont: "保险",
          img: [require("@/assets/qd/bx.jpg")],
          odd: "53202111031212305",
          url: "http://www.guorenpcic.com/web/bdyz/index.jhtml",
          icon: "bx_10icon",
          trademark: require("@/assets/images/bx-logo.png"),
        },
      ],
      cy: [
        {
          cont: "种植基地",
          img: [require("@/assets/cy/zzjd.png")],
          odd: "80025668823062662003",
          url: "http://www.hxytea.com/quality/manor",
          icon: "zz_01icon",
          trademark: require("@/assets/cy/zzjd-logo.png"),
        },
        {
          cont: "有机认证",
          img: [require("@/assets/images/qs.png")],
          // odd: "80025668823062662003",
          // url: "http://cx.cnca.cn/CertECloud/resultSn/skipResultSnFull",
          icon: "TrackBack-test",
          // trademark: require("@/assets/images/yjrz-logo.png"),
        },
        {
          cont: "重金属和农药残留检测报告",
          img: [
            require("@/assets/cy/jcbg1.png"),
            require("@/assets/cy/jcbg2.png"),
            require("@/assets/cy/jcbg3.png"),
          ],
          url: "http://report.ponytest.com/",
          icon: "jc_03icon",
          trademark: require("@/assets/images/pony-logo.png"),
        },
        // {
        //   cont: "宝华工厂",
        //   img: [require("@/assets/images/bhgc-logo2.png")],
        //   odd: "CK202103061006",
        //   url: "http://taisheng.realbrand.net",
        //   icon: "gc_04icon",
        //   trademark: require("@/assets/images/bhgc-logo.png"),
        // },
        {
          cont: "赋码公司",
          img: [require("@/assets/images/fm.png")],
          odd: "CY202103061006",
          url: "http://www.gdlasertech.com",
          icon: "fm_05icon",
          trademark: require("@/assets/images/fmgs-logo.png"),
        },
        {
          cont: "碳足迹",
          img: [
            require("@/assets/images/tzj1.png"),
            require("@/assets/images/tzj2.png"),
            require("@/assets/images/tzj3.png"),
            require("@/assets/images/tzj4.png"),
          ],
          odd: "53202111031212305",
          url: "https://www.cti-cert.com",
          icon: "jc_03icon",
          trademark:
            "https://www.cti-cert.com/upload/2021-03/161595002496180000.svg",
        },
        {
          cont: "物流",
          img: [require("@/assets/images/wl.png")],
          odd: "FD54045 / 76F01",
          url: "https://www.ky-express.com",
          icon: "wl_06icon",
          trademark: require("@/assets/images/wl-logo.png"),
        },
        {
          cont: "终端门店",
          img: [require("@/assets/cy/zdmd.png")],
          // url: "https://www.crv.com.cn",
          icon: "xs_07icon",
          // trademark: require("@/assets/images/xs-logo.png"),
        },
        {
          cont: "银行",
          img: [require("@/assets/cy/yh.png")],
          odd: "53202111031212305",
          url: "https://www.boc.cn",
          icon: "yh_09icon",
          trademark: require("@/assets/images/yh-logo.png"),
        },
        {
          cont: "保险",
          img: [require("@/assets/cy/bx.jpg")],
          odd: "53202111031212305",
          url: "http://www.guorenpcic.com/web/bdyz/index.jhtml",
          icon: "bx_10icon",
          trademark: require("@/assets/images/bx-logo.png"),
        },
      ],
      zywy: [
        {
          cont: "质量检测报告",
          img: [require("@/assets/images/zywy-jcbg.png")],
          url: "http://www.czzjzx.cn/tcsn",
          odd: "RK202103061006",
          icon: "jc_03icon",
          // trademark: require("@/assets/images/pony-logo.png"),
        },
        {
          cont: "赋码公司",
          img: [require("@/assets/images/fm.png")],
          odd: "CY202103061006",
          url: "http://www.gdlasertech.com",
          icon: "fm_05icon",
          trademark: require("@/assets/images/fmgs-logo.png"),
        },
        {
          cont: "碳足迹",
          img: [
            require("@/assets/images/tzj1.png"),
            require("@/assets/images/tzj2.png"),
            require("@/assets/images/tzj3.png"),
            require("@/assets/images/tzj4.png"),
          ],
          odd: "53202111031212305",
          url: "https://www.cti-cert.com",
          icon: "jc_03icon",
          trademark:
            "https://www.cti-cert.com/upload/2021-03/161595002496180000.svg",
        },
        {
          cont: "物流",
          img: [require("@/assets/images/wl.png")],
          url: "https://www.ky-express.com",
          icon: "wl_06icon",
          trademark: require("@/assets/images/wl-logo.png"),
        },
        {
          cont: "总经销商",
          img: [require("@/assets/images/qs.png")],
          // url: "https://www.crv.com.cn",
          icon: "xs_07icon",
          // trademark: require("@/assets/images/xs-logo.png"),
        },
        {
          cont: "分经销商",
          img: [require("@/assets/images/qs.png")],
          odd: "53202111031212305",
          // url: "https://www.crv.com.cn",
          icon: "ls_08icon",
          // trademark: require("@/assets/images/xs-logo.png"),
        },
        {
          cont: "终端门店",
          img: [require("@/assets/images/qs.png")],
          odd: "53202111031212305",
          // url: "https://www.crv.com.cn",
          icon: "xs_07icon",
          // trademark: require("@/assets/images/xs-logo.png"),
        },
        {
          cont: "银行",
          img: [require("@/assets/images/zy-yh.png")],
          odd: "53202111031212305",
          url: "https://www.boc.cn",
          icon: "yh_09icon",
          trademark: require("@/assets/images/yh-logo.png"),
        },
        {
          cont: "保险",
          img: [require("@/assets/images/zywy-bx.jpg")],
          odd: "53202111031212305",
          url: "http://www.guorenpcic.com/web/bdyz/index.jhtml",
          icon: "bx_10icon",
          trademark: require("@/assets/images/bx-logo.png"),
        },
      ],
      mlx: [
        {
          cont: "船运单",
          img: [require("@/assets/mlx/cy.png")],
          url: "http://www.coscoshipping.com",
          icon: "jc_03icon",
          trademark: require("@/assets/mlx/cy-logo.png"),
        },
        {
          cont: "报关单",
          img: [require("@/assets/mlx/bg.png")],
          url: "https://app.singlewindow.cn/qspserver/sw/qsp/query/view/iePortNetwork",
          icon: "jc_03icon",
          trademark:
            "https://app.singlewindow.cn/static/image/footer-img01.png",
        },
        {
          cont: "出入境检测检疫证明",
          img: [require("@/assets/mlx/cr.png")],
          url: "http://online.customs.gov.cn",
          icon: "jc_03icon",
          // trademark: require("@/assets/images/pony-logo.png"),
        },
        {
          cont: "质量检测报告",
          img: [require("@/assets/mlx/zljc.png")],
          url: "http://www.cbmtc.com",
          icon: "jc_03icon",
          trademark: require("@/assets/mlx/zljc-logo.png"),
        },
        {
          cont: "放射性检测报告",
          img: [require("@/assets/mlx/fsx.png")],
          // url: "http://report.ponytest.com/",
          icon: "jc_03icon",
          // trademark: require("@/assets/images/pony-logo.png"),
        },
        {
          cont: "赋码公司",
          img: [require("@/assets/images/fm.png")],
          url: "http://www.gdlasertech.com",
          icon: "fm_05icon",
          trademark: require("@/assets/images/fmgs-logo.png"),
        },
        {
          cont: "碳足迹",
          img: [
            require("@/assets/images/tzj1.png"),
            require("@/assets/images/tzj2.png"),
            require("@/assets/images/tzj3.png"),
            require("@/assets/images/tzj4.png"),
          ],
          odd: "53202111031212305",
          url: "https://www.cti-cert.com",
          icon: "jc_03icon",
          trademark:
            "https://www.cti-cert.com/upload/2021-03/161595002496180000.svg",
        },
        {
          cont: "物流",
          img: [require("@/assets/images/wl.png")],
          odd: "FD54045 / 76F01",
          url: "https://www.ky-express.com",
          icon: "wl_06icon",
          trademark: require("@/assets/images/wl-logo.png"),
        },
        {
          cont: "终端门店",
          img: [require("@/assets/images/qs.png")],
          odd: "53202111031212305",
          url: "http://www.marmocer.cn",
          icon: "xs_07icon",
          trademark: require("@/assets/mlx/logo.png"),
        },
        {
          cont: "银行",
          img: [require("@/assets/mlx/yh.png")],
          odd: "53202111031212305",
          url: "https://www.boc.cn",
          icon: "yh_09icon",
          trademark: require("@/assets/images/yh-logo.png"),
        },
        {
          cont: "保险",
          img: [require("@/assets/images/mlx.jpg")],
          odd: "53202111031212305",
          url: "http://www.guorenpcic.com/web/bdyz/index.jhtml",
          icon: "bx_10icon",
          trademark: require("@/assets/images/bx-logo.png"),
        },
      ],
      other: [
        {
          cont: "种植基地",
          img: [require("@/assets/images/qs.png")],
          odd: "80025668823062662003",
          // url: "http://taisheng.realbrand.net",
          icon: "zz_01icon",
          // trademark: require("@/assets/images/zzjd-logo.png"),
        },
        {
          cont: "有机认证",
          img: [require("@/assets/images/qs.png")],
          // odd: "80025668823062662003",
          // url: "http://cx.cnca.cn/CertECloud/resultSn/skipResultSnFull",
          icon: "TrackBack-test",
          // trademark: require("@/assets/images/yjrz-logo.png"),
        },
        {
          cont: "检测认证",
          img: [require("@/assets/images/qs.png")],
          // url: "http://report.ponytest.com/",
          odd: "RK202103061006",
          icon: "jc_03icon",
          // trademark: require("@/assets/images/pony-logo.png"),
        },
        // {
        //   cont: "宝华工厂",
        //   img: [require("@/assets/images/bhgc-logo2.png")],
        //   odd: "CK202103061006",
        //   url: "http://taisheng.realbrand.net",
        //   icon: "gc_04icon",
        //   trademark: require("@/assets/images/bhgc-logo.png"),
        // },
        {
          cont: "赋码公司",
          img: [require("@/assets/images/qs.png")],
          odd: "CY202103061006",
          // url: "http://www.gdlasertech.com",
          icon: "fm_05icon",
          // trademark: require("@/assets/images/fmgs-logo.png"),
        },
        {
          cont: "物流",
          img: [require("@/assets/images/qs.png")],
          odd: "FD54045 / 76F01",
          // url: "https://www.ky-express.com",
          icon: "wl_06icon",
          // trademark: require("@/assets/images/wl-logo.png"),
        },
        {
          cont: "销售",
          img: [require("@/assets/images/qs.png")],
          odd: "53202111031212305",
          // url: "https://www.crv.com.cn",
          icon: "xs_07icon",
          // trademark: require("@/assets/images/xs-logo.png"),
        },
        {
          cont: "零售店",
          img: [require("@/assets/images/qs.png")],
          odd: "53202111031212305",
          // url: "https://www.crv.com.cn",
          icon: "ls_08icon",
          // trademark: require("@/assets/images/xs-logo.png"),
        },
        {
          cont: "银行",
          img: [require("@/assets/images/qs.png")],
          odd: "53202111031212305",
          // url: "https://www.boc.cn",
          icon: "yh_09icon",
          // trademark: require("@/assets/images/yh-logo.png"),
        },
        {
          cont: "保险",
          img: [require("@/assets/images/qs.png")],
          odd: "53202111031212305",
          // url: "http://www.guorenpcic.com/web/bdyz/index.jhtml",
          icon: "bx_10icon",
          // trademark: require("@/assets/images/bx-logo.png"),
        },
        {
          cont: "消费者",
          img: [require("@/assets/images/qs.png")],
          odd: "53202111031212305",
          // url: "https://www.boc.cn",
          icon: "xfz_11icon",
          // trademark: require("@/assets/images/test.png"),
        },
      ],
      isShow: 0,
      ulW: 0,
      LiLength: 0,
      UlWidth: 0,
      i: 0,
      Amplification: "",
      AmplificationShow: false,
      store: 0,
      scrollLength: "",
      Store: "",
      CommodityDetails: "",
      manufacturer: "",
    };
  },

  filters: {
    Price(p) {
      if (p === undefined || p === null) {
        return "-";
      } else {
        if (String(Number(p)).indexOf(".")) {
          return p.toFixed(2);
        } else {
          return p;
        }
      }
    },
    nowTime(time) {
      return formatDate(time);
    },
    nowDay(day) {
      return formatDay2(day);
    },
    oneYear(day) {
      return formatDay2(day + 31104000000);
    },
  },
  created() {
    this.listenerFunction();
    this.getDetails();
    this.getEnterprise();

    if (this.$route.query.id === "6925670136911") {
      this.store = 0;
      this.scrollLength = this.li.length;
      this.manufacturer = "http://www.ba119.com.cn/";
      //泡沫消火栓
    } else if (
      this.$route.query.id === "6925670136910" ||
      this.$route.query.id === "6959315400691" ||
      this.$route.query.id === "6959315400692" ||
      this.$route.query.id === "6959315400693" ||
      this.$route.query.id === "6959315400694" ||
      this.$route.query.id === "6959315400695" ||
      this.$route.query.id === "6959315400696"
    ) {
      this.store = 1;
      this.scrollLength = this.xf.length;
      this.manufacturer = "http://www.ba119.com.cn/";

      //所有消防
    } else if (
      this.$route.query.id === "41595508418" ||
      this.$route.query.id === "41595508410" ||
      this.$route.query.id === "41595508401" ||
      this.$route.query.id === "41595508402" ||
      this.$route.query.id === "41595508403" ||
      this.$route.query.id === "41595508404" ||
      this.$route.query.id === "41595508405" ||
      this.$route.query.id === "41595508406" ||
      this.$route.query.id === "41595508409" ||
      this.$route.query.id === "41595508411" ||
      this.$route.query.id === "41595508412" ||
      this.$route.query.id === "41595508413" ||
      this.$route.query.id === "41595508407" ||
      this.$route.query.id === "41595508408"
    ) {
      this.store = 2;
      this.scrollLength = this.shoes.length;
      this.manufacturer = "http://www.qiaodan.com/";

      //鞋
    }
    // else if (
    //   this.$route.query.id === "41595508407" ||
    //   this.$route.query.id === "41595508408"
    // ) {
    //   this.store = 3;
    //   this.scrollLength = this.clothes.length;
    //   //衣物
    // }
    else if (
      this.$route.query.id === "41595508452" ||
      this.$route.query.id === "6959315400191" ||
      this.$route.query.id === "6959315400192" ||
      this.$route.query.id === "41595508451" ||
      this.$route.query.id === "41595508450"
    ) {
      this.store = 5;
      this.scrollLength = this.cy.length;
      this.manufacturer = this.cy[0].url;
    } else if (
      this.$route.query.id === "6920999701712" ||
      this.$route.query.id === "6920999701731" ||
      this.$route.query.id === "6911348220027" ||
      this.$route.query.id === "6920999701732" ||
      this.$route.query.id === "6911348220077" ||
      this.$route.query.id === "6911348220028" ||
      this.$route.query.id === "6911348220029" ||
      this.$route.query.id === "6920547824910" ||
      this.$route.query.id === "6920999701730"
    ) {
      this.store = 6;
      this.scrollLength = this.zywy.length;
      this.manufacturer = "http://www.joyou.com/";
    } else if (
      this.$route.query.id === "6910999701711" ||
      this.$route.query.id === "6910999701710" ||
      this.$route.query.id === "6925670136999" ||
      this.$route.query.id === "6925670136701" ||
      this.$route.query.id === "6910999701210" ||
      this.$route.query.id === "6910999701713" ||
      this.$route.query.id === "6910999701712" ||
      this.$route.query.id === "6910999701211" ||
      this.$route.query.id === "6910999701714"
    ) {
      this.store = 7;
      this.scrollLength = this.mlx.length;
      this.manufacturer = "http://www.marmocer.cn/";
    } else {
      this.store = 4;
      this.scrollLength = this.other.length;
      //其他
    }
  },

  mounted() {
    this.LiLength = this.$refs.ulWidth.children.length;
    // console.log(this.$refs.ulWidth.children);
    for (let i = 0; i < this.LiLength; i++) {
      this.UlWidth += this.$refs.ulWidth.children[i].offsetWidth;
    }
    // console.log(this.UlWidth);
    this.$refs.ulWidth.width = this.UlWidth;
  },
  beforeDestroy() {
    document.removeEventListener("scroll", this.listenerFunction);
  },
  methods: {
    OnLi(i) {
      this.isShow = i;
    },
    up() {
      if (this.isShow === 0) {
        this.isShow = this.scrollLength - 1;
      } else {
        this.isShow--;
      }
    },
    down() {
      if (this.isShow >= this.scrollLength - 1) {
        this.isShow = 0;
      } else {
        this.isShow++;
      }
    },
    listenerFunction(e) {
      document.addEventListener("scroll", this.handleScroll, true);
    },
    handleScroll() {
      if (window.pageYOffset >= 280) {
        // this.$refs.nav
        this.$refs.nav.style.position = "fixed";
      } else {
        this.$refs.nav.style.position = "relative";
      }
      // console.log(window.pageYOffset);
    },
    copy() {
      let clipboard = new this.clipboard("#express_info_right");
      let that = this;
      clipboard.on("success", function () {
        alert("复制成功");
        // that.$message.success("复制成功");
        clipboard.destroy();
      });
      clipboard.on("error", function () {
        alert("复制失败");
        // that.$message.error("复制失败");
      });
    },

    leftclick() {
      let Lilength = this.$refs.ulWidth.children.length;
      if (this.i < Lilength - 8) {
        this.i++;
        this.ulW += -144;
        this.$refs.ulWidth.style.left = this.ulW + "px";
      }
    },
    rightclick() {
      if (this.i > 0) {
        this.i--;
        this.ulW += 144;
        this.$refs.ulWidth.style.left = this.ulW + "px";
      }
    },
    // etter(str) {
    //   var result;
    //   var reg = /[a-zA-Z]+/; //[a-zA-Z]表示匹配字母，g表示全局匹配
    //   while ((result = str.match(reg))) {
    //     //判断str.match(reg)是否没有字母了
    //     str = str.replace(result[0], ""); //替换掉字母  result[0] 是 str.match(reg)匹配到的字母
    //   }
    //   return str;
    // },
    amplification(index, i) {
      // console.log(index,i);
      this.Amplification = this.$refs.IMG[index].children[i].src;
      // console.log(this.$refs.IMG[index].children[i].src);
    },
    goA(i) {
      console.log(i);
      if (i === 1) {
        window.open("http://taisheng.realbrand.net", "_blank");
      } else if (i === 3) {
        window.open("http://www.guorenpcic.com/web/bdyz/index.jhtml", "_blank");
      } else if (i === 5) {
        this.isShow = 5;
        // window.open("https://www.ky-express.com", "_blank");
      }
    },
    goB(i) {
      console.log(i);
      if (i === 1) {
        window.open("http://www.leishushuchayou.com", "_blank");
      } else if (i === 3) {
        window.open("http://www.guorenpcic.com/web/bdyz/index.jhtml", "_blank");
      } else if (i === 5) {
        this.isShow = 4;

        // window.open("https://www.ky-express.com", "_blank");
      }
    },

    //获取商品详情
    async getDetails() {
      const data = await this.$API.store.getCommodityDetails({
        object: {
          barcode: this.$route.query.id,
          // barcode: '6959315400191',
          enterpriseId: this.$route.query.enterpriseId,
          // enterpriseId: '24754B81C38940D3B946F36FB7BECD4B',
        },
      });
      this.CommodityDetails = data.data.data;
      console.log(this.CommodityDetails);
    },
    //获取店铺信息
    async getEnterprise() {
      const data = await this.$API.homePage.enterprise({
        object: {
          barcode: this.$route.query.id,
          // barcode: '6959315400191',
          commodityCode: "",
          enterpriseId: this.$route.query.enterpriseId,
          // enterpriseId:'24754B81C38940D3B946F36FB7BECD4B',
        },
        pageNum: 1,
        pageSize: 1,
      });
      this.Store = data.data.data;
      console.log(this.Store);
    },
  },
};
</script>

<style lang="scss" scoped>
#track-back {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #fff;
  border-top: 2px solid #00aeeb;

  .website {
    width: 1200px;
    height: 56px;
    margin: 30px 0;
    display: flex;
    img {
      // width: 102px;
      height: 56px;
      border: 1px solid #ebebeb;
      // background: #006cce;
    }
    div {
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      margin-left: 16px;
      p {
        font-size: 14px;
        color: #666666;
      }
      a {
        font-size: 16px;
        color: #438afe;
      }
    }
  }
  .website:nth-child(11) {
    img {
      border: none;
    }
  }
  .content_box {
    width: 1200px;
    .nav {
      display: flex;
      top: -1px;
      width: 1200px;
      z-index: 999;
      background: #fbfbfb;
      border: 1px solid #e8e8e8;
      align-items: center;
      position: relative;
      div {
        width: 1200px;
        overflow: hidden;
        ul {
          display: flex;
          justify-content: center;
          position: relative;
          left: 0;
          top: 0;
          transition: all 0.3s;

          li {
            display: flex;
            align-items: center;
            height: 46px;
            .svg-icon {
              margin-right: 6px;
              color: #aaa;
              font-size: 16px;
            }
            img {
              margin: 0 5px;
            }
            p {
              // width: 56px;
              // text-align: center;
              font-size: 14px;
              color: #666666;
              cursor: pointer;
            }
          }
          li:last-child {
            img {
              display: none;
            }
          }
          .onLi {
            p {
              color: #00aeeb;
            }
            .svg-icon {
              margin-right: 6px;
              color: #00aeeb;
            }
          }
        }
      }
      .arrow {
        -moz-user-select: none;
        -ms-user-select: none;
        -webkit-user-select: none;
        user-select: none;
        width: 14px;
        height: 46px;
        cursor: pointer;
        font-size: 14px;
        margin: 0 12px;
        color: #999;
      }
      .arrow:hover {
        color: #00aeeb;
      }
    }
    .content {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 1200px;
      // height: 1300px;
      border: 1px solid #e8e8e8;
      border-top: none;
      margin-bottom: 30px;
      position: relative;
      padding: 40px 0 30px 0;
      .documents {
        width: 680px;
        height: 1000px;
        // cursor: pointer;
        // width: auto;
        // height: auto;
      }
      .imgBox {
        width: 680px;
        height: 1020px;
        overflow-y: auto;
        // display: flex;
        overflow-x: auto;
        img {
          // cursor: pointer;
        }
      }
      .imgBox-y {
        display: block;
        img {
          height: auto;
        }
      }
      .imgBox-jcrz {
        display: block;
        height: 961px;
        overflow-x: hidden;
        img {
          height: auto;
        }
      }
      .imgBox-auto {
        width: auto;
        height: auto;
        img {
          width: auto;
          height: auto;
        }
      }
      .imgBox-sm {
        width: 390px;
        height: 556px;
        img {
          width: auto;
          height: auto;
        }
      }
      .arrow-tp {
        top: 192px !important;
      }
      .arrow-tp2 {
        top: 250px !important;
      }
      .arrow-yh {
        top: 280px !important;
      }
      .arrow-qs {
        top: 120px !important;
      }
      .arrow-other {
        top: 120px !important;
      }
      .arrow {
        width: 40px;
        height: 84px;
        background: #000000;
        opacity: 0.15;
        position: absolute;
        top: 479px;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        -moz-user-select: none;
        -ms-user-select: none;
        -webkit-user-select: none;
        user-select: none;
      }
      .arrow-left {
        left: 220px;
      }
      .arrow-right {
        right: 220px;
      }
      p {
        position: absolute;
        top: 462px;
        left: 50%;
        margin-left: -18px;
        cursor: pointer;
        background: #00aeeb;
        color: #fff;
        width: 36px;
        height: 18px;
      }
      // .text {
      //   width: 680px;
      //   // height: 276px;
      //   background: #ffffff;
      //   border: 1px solid #e8e8e8;
      //   margin-top: 20px;
      //   .title {
      //     height: 42px;
      //     background: #f8f9fb;
      //     border-bottom: 1px solid #e8e8e8;
      //     font-size: 16px;
      //     font-family: Microsoft YaHei;
      //     font-weight: bold;
      //     color: #333333;
      //     display: flex;
      //     align-items: center;
      //     padding-left: 20px;
      //   }
      //   .cont {
      //     padding: 24px;
      //     h3 {
      //       font-size: 16px;
      //       font-family: Source Han Serif SC;
      //       font-weight: bold;
      //       line-height: 24px;
      //       color: #036eb8;
      //     }
      //     p {
      //       font-size: 14px;
      //       line-height: 28px;
      //       color: #666666;
      //       margin-top: 12px;
      //       a {
      //         color: #438afe;
      //       }
      //     }
      //   }
      // }
    }
    .xfz {
      position: relative;
      padding: 40px 0;
      display: flex;
      justify-content: center;
      .arrow {
        position: absolute;
        top: 50%;
        -moz-user-select: none;
        -ms-user-select: none;
        -webkit-user-select: none;
        user-select: none;
        width: 40px;
        height: 84px;
        background: #000000;
        opacity: 0.15;
        position: absolute;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: -42px;
      }
      .arrow-left {
        left: 200px;
      }
      .arrow-right {
        right: 200px;
      }
      .Box {
        width: 720px;
        height: 1000px;
        overflow: auto;
        padding: 0 24px;
        div {
          display: flex;
          flex-direction: column;
          align-items: center;
        }
        .cont1 {
          padding: 50px 0 86px 0;
          border-bottom: 1px solid #e6e6e6;
          color: #333333;
          img {
            width: 106px;
            height: 106px;
            border: 1px solid #dcdcdc;
            border-radius: 50%;
          }
          p {
            margin: 24px 0 26px 0;
            font-size: 36px;
            font-family: PingFang SC;
          }
          h3 {
            // font-weight: bold;
            font-size: 46px;
          }
        }
        .cont2,
        .cont3 {
          padding: 48px 0;
          border-bottom: 1px #e7e7e7 dashed;
          ul {
            width: 100%;
            display: flex;
            justify-content: space-between;
            li {
              font-size: 28px;
              font-family: PingFang SC;
              font-weight: 400;
              color: #333333;
            }
          }
        }
        .cont3 ul {
          margin-bottom: 20px;
          .name {
            width: 200px;
            padding-right: 20px;
            /*文本强制不换行*/

            white-space: nowrap;

            /*文本溢出显示省略号*/

            text-overflow: ellipsis;

            /*溢出的部分隐藏*/

            overflow: hidden;
          }
          .storeName {
            width: 500px;
            /*文本强制不换行*/

            white-space: nowrap;

            /*文本溢出显示省略号*/

            text-overflow: ellipsis;

            /*溢出的部分隐藏*/

            overflow: hidden;
          }
        }
        .cont4,
        .cont5 {
          padding: 48px 0;
          border-bottom: 1px solid #e7e7e7;
          div {
            font-size: 28px;
            flex-direction: row;
            width: 100%;
            margin-bottom: 16px;
            p {
              width: 112px;
              line-height: 40px;
              color: #999999;
            }
            span {
              margin-left: 52px;
              color: #333333;
            }
            a {
              margin-left: 52px;
              color: #438afe;
              text-decoration: underline;
            }
          }
        }
      }
    }
  }
  .amplification_box {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1000;
    overflow-y: auto;
    text-align: center;
    background: rgba($color: #000000, $alpha: 0.3);
    p {
      position: fixed;
      right: 16px;
      top: 0;
      cursor: pointer;
      color: #fff;
      font-size: 20px;
      line-height: 28px;
      width: 80px;
      height: 30px;
      background: rgba($color: #000000, $alpha: 0.5);
      img {
        vertical-align: middle;
      }
    }
  }
}
</style>